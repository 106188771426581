"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("Utils/initSentry");
require("./polyfill");
require("reflect-metadata");
require("angular");
require("angular-sanitize");
require("angular-animate");
require("Utils/CreateUi");
require("angular-scroll");
require("angular-strap/dist/angular-strap.min");
require("angular-strap/dist/angular-strap.tpl");
require("./Styles/main.less");
require.context("./Assets", true, /\.(svg|png|jpg|eot|ttf|woff|woff2|json)$/);
require("./Application/app.js");
require("./ProjectView/app.js");
