// Generated by CoffeeScript 1.12.7

/*
  This is a simple wrapper directive for the video background. It reuses the
  existing code from the client by make()ing it with our scope params.
 */

(function () {
  var VideoBackground;

  VideoBackground = require("VideoBackground/VideoBackground.js");

  module.exports = angular.module("videoBackground", []).directive("videoBackground", function ($rootScope, $timeout) {
    return {
      restrict: "E",
      template: '<div ui="videoBackground.ui"></div>',
      scope: {
        url: "@",
      },
      link: function (scope, elem, attrs) {
        var childScope, di, invoke, videoBackground;
        di = $rootScope.di;
        childScope = di.child();
        invoke = di.get("invoke");
        videoBackground = childScope.make(invoke(VideoBackground), {
          url: scope.url,
        });
        return (scope.videoBackground = videoBackground);
      },
    };
  });
}.call(this));
